<template>
    <div>
        <b-row>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('semester_1')">
                    <div class="label-as-input">{{ isNotNullValue(formData.academic_year) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('exam_type')">
                    <div class="label-as-input">{{ isNotNullValue(formData.type) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('course_code')">
                    <div class="label-as-input">{{ isNotNullValue(formData.course_code1) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('course_code')">
                    <div class="label-as-input">{{ isNotNullValue(formData.course_code2) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('student_number_long')">
                    <div class="label-as-input">{{ isNotNullValue(formData.student_number) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('name_surname')">
                    <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        props: {
            formData: {
                type: Object,
                default: () => {}
            }
        },
    }
</script>
